<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class="flex">
        <div class="">
          <button
            class="p-2 rounded-md text-xs text-black"
            @click="
              $router.push({
                name: 'CorporateVerification',
              })
            "
          >
            &laquo; Back
          </button>
        </div>
      </div>
      <h1 class="text-2xl font-bold">Corporate Verification Details</h1>
      <div class="flex my-10 flex-col">
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4 mb-3">
          <span
            class="text-right px-3 cursor-pointer py-2 text-xs uppercase text-white bg-blue-800"
            @click="
              $router.push({
                name: 'ViewVerification',
                params: {
                  id: corporate.kyc_record.businessId,
                },
              })
            "
          >
            <i class="bi bi-arrow-left-circle"></i> View all verification
          </span>
          <span
            class="text-right px-3 py-2 text-xs uppercase text-white"
            style="float: right"
            :class="
              corporate.kyc_record.status == 'VERIFIED'
                ? 'bg-green-500'
                : corporate.status == 'FAILED'
                ? 'bg-red-600'
                : 'bg-gray-600'
            "
          >
            {{
              corporate.status == "VERIFIED"
                ? "VERIFIED"
                : corporate.status == "FAILED"
                ? "FAILED"
                : "Awaiting approval"
            }}
          </span>
        </div>
        <div class="w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4">
          <div
            class="flex justify-center flex-col p-5 border-2 border-gray-200 shadow-lg rounded-md"
          >
            <h4 class="text-xl font-bold">Corporate Info</h4>
            <div class="grid grid-cols-3 gap-3 w-full">
              <div class="justify-center flex-col items-center py-2">
                <div>
                  <h4 class="font-bold text-xs">Registered Name</h4>
                  <p>
                    {{ corporate.businessInfo.name || "N/A" }}
                  </p>
                  <h4 class="font-bold text-xs mt-5">Legal Name</h4>
                  <p>
                    {{ corporate.kyc_record.legal_name || "N/A" }}
                  </p>
                  <h4 class="font-bold text-xs mt-5">RC/BN number</h4>
                  <p>
                    {{ corporate.verificationReference || "N/A" }}
                  </p>
                  <h4 class="font-bold text-xs mt-5">CAC certificate upload</h4>
                  <img
                    :src="corporate.kyc_record.imageOfDocument"
                    alt="No cac certificate upload"
                    style="width: 200px; height: 200px"
                    class="mb-5"
                  />
                  <h4 class="font-bold text-xs">Certified Application Form</h4>
                  <img
                    :src="corporate.kyc_record.certified_application_doc"
                    alt="No location photo to show"
                    style="width: 200px; height: 200px"
                  />
                  <h4 class="font-bold text-xs">
                    Corporate verification status:
                  </h4>
                  <p
                    class="inline px-1"
                    :class="
                      corporate.kyc_record.status == 'VERIFIED'
                        ? 'bg-green-500 text-black'
                        : corporate.kyc_record.status == 'FAILED'
                        ? 'bg-red-600 text-white'
                        : 'bg-gray-600 text-white'
                    "
                  >
                    {{ corporate.kyc_record.status || "N/A" }}
                  </p>
                </div>
              </div>
              <div class="justify-center flex-col items-center py-2">
                <h4 class="font-bold text-xs">Business Registration Type</h4>
                <p>
                  {{ corporate.documentType || "N/A" }}
                </p>
                <h4 class="font-bold text-xs mt-5">
                  Tax Identification Number
                </h4>
                <p>
                  {{ corporate.kyc_record.tax_id_number || "N/A" }}
                </p>

                <h4 class="font-bold text-xs mt-5">Director ID</h4>
                <img
                  :src="corporate.kyc_record.userImage"
                  alt="No location photo to show"
                  style="width: 200px; height: 200px"
                />
                <h4 class="font-bold text-lg mt-5">Smile Identity Data</h4>
                <h5 class="font-bold text-xs mt-5">Business Name</h5>
                <p>
                  {{ corporate.kyc_record.provider_legal_name || "N/A" }}
                </p>
                <h5 class="font-bold text-xs mt-5">
                  Business Verification Status
                </h5>
                <p>
                  {{
                    corporate.kyc_record.provider_verification_status || "N/A"
                  }}
                </p>
                <h5 class="font-bold text-xs mt-5">Status</h5>
                <p>
                  {{ corporate.kyc_record.business_status || "N/A" }}
                </p>
              </div>
            </div>
            <div
              class="text-right mt-5"
              v-if="corporate.kyc_record.status === 'PENDING'"
            >
              <button
                @click="handleCorporateModal(corporate, 'DECLINED')"
                class="inline-block mr-2 uppercase border-2 border-red-600 hover:bg-red-700 focus:shadow-outline focus:outline-none hover:text-white text-red-800 h-10 text-xs px-5 rounded"
              >
                Reject
              </button>
              <button
                @click="handleCorporateModal(corporate, 'APPROVED')"
                class="inline-block uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-5 rounded"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="alertDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <i
            class="bi bi-check-circle-fill text-green-400 text-6xl"
            v-if="currKYC.status === 'APPROVED'"
          ></i>
          <i class="bi bi-x-circle-fill text-yellow-400 text-6xl" v-else></i>
          <p class="text-xl font-bold w-3/4 mx-auto text-center">
            {{ messg }}
          </p>
          <small class="text-xs"
            >Kindly confirm that you want to perform this action below.</small
          >
          <textarea
            v-if="currKYC.status === 'DECLINED'"
            v-model="comment"
            class="border-2 border-gray-200"
            rows="3"
          ></textarea>
          <div class="flex justify-between w-3/4 mt-5 mx-auto">
            <button
              @click="updateKYCStatus"
              class="uppercase shadow mx-auto hover:bg-blue-600 bg-blue-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 px-6 rounded"
            >
              Confirm
            </button>
            <button
              @click="alertDiv = false"
              class="uppercase shadow mx-auto hover:bg-red-600 bg-red-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 px-6 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Loading from "./general-components/Loading.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      moment,
      documents: null,
      isLoading: false,
      alertDiv: false,
      messg: "",
      comment: "",
      currKYC: {},
      corporate: null,
    };
  },
  components: {
    Loading,
    Multiselect,
  },
  computed: {
    ...mapGetters(["GET_VERIFIED_DOCUMENTS"]),
    filterDocuments() {
      return this.GET_VERIFIED_DOCUMENTS.data
        ? this.GET_VERIFIED_DOCUMENTS.data.items
        : [];
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.loadVerificationDocument();
  },
  methods: {
    ...mapActions(["FETCH_SINGLE_VERIFICATION_DOCUMENT_NEW"]),
    async updateKYCStatus() {
      const status =
        this.currKYC.status == "APPROVED" ? "APPROVED" : "DECLINED";
      const comment = "APPROVED" ? "" : this.comment;
      this.isLoading = true;
      let payload = {
        comment: this.comment,
        status,
        id: this.currKYC.id,
      };

      let res;

      if (status == "APPROVED") {
        res = await this.$store.dispatch("APPROVE_KYC", payload);
      } else {
        res = await this.$store.dispatch("DISAPPROVE_KYC", payload);
      }

      if (res.status) {
        alert(res.message);
        this.isLoading = false;
        this.alertDiv = false;
        this.loadVerificationDocument();
      } else {
        alert(res.message);
        this.isLoading = false;
      }
    },
    handleCorporateModal(corporate, status) {
      this.messg =
        status === "DECLINED"
          ? "Are you sure you want to reject this verification"
          : "Are you sure you want to approve this verification";
      this.currKYC.id = corporate.kyc_record._id;
      this.currKYC.status = status;
      this.alertDiv = true;
    },
    async loadVerificationDocument() {
      this.isLoading = true;
      let res = await this.$store.dispatch(
        "FETCH_SINGLE_VERIFICATION_DOCUMENT",
        {
          id: this.$route.params.id,
        }
      );
      this.isLoading = false;
      // console.log(res.data);
      if (res.status) {
        this.isLoading = false;
        this.corporate = res.data;
        console.log(this.corporate.kyc_record.certified_application_doc);
      } else {
        this.isLoading = false;
        alert("Sorry, could not find any detail for this record");
      }
    },
  },
};
</script>

<style>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
